const user = {
  namespaced: true,
  state: {
    userInfo: {},
    token: "",
    refresh: "",
  },
  mutations: {
    //存用户信息
    saveUserInfo(state, payload) {
      state.userInfo = payload;
    },
    //存accessToken
    saveToken(state, payload) {
      state.token = payload;
    },
    // 存refreshToken
    saveRefresh(state, payload) {
      state.refresh = payload;
    },
    // 退出登录
    logOut(state) {
      state.userInfo = {};
      state.token = "";
      state.refresh = "";
    },
  },
};
export default user;
