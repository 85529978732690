<template>
  <el-container style="height: 100%">
    <el-header style="background: #4ad089">
      <TopView></TopView>
    </el-header>

    <el-container>
      <el-aside style="width: 150px">
        <MenuView></MenuView>
      </el-aside>
      <el-main style="padding: 10px;background: #F2F3F7;">
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import MenuView from "./menu.vue";
import TopView from "./top.vue";
export default {
  name: "MoldManageIndex",
  components: {
    MenuView,
    TopView,
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>
