<template>
  <div>
    <el-dialog title="修改密码" :visible.sync="visible" width="35%" @closed="dialogClosed">
      <el-form :model="passForm" :rules="rules" ref="passFormRef" label-width="auto">
        <el-form-item label="新密码" prop="password">
          <el-input v-model.trim="passForm.password" placeholder="请输入密码" id="password"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="checkPass">
          <el-input placeholder="请再次确认新密码" v-model.trim="passForm.checkPass" id="checkPass"></el-input>
        </el-form-item>
        <el-form-item prop="checkPass">
          <b style="color: red; display: block; font-size: 12px; width: 100%; text-align: center">(密码格式要求必须有英文字母和数字，长度在6-18位)</b>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="success" plain @click="dialogClosed">取消</el-button>
        <el-button type="success" @click="confirmReset('passFormRef')" :disabled="btnAbled">确认修改</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import md5 from "js-md5";
import { resetPassword } from "@/views/User/api";
export default {
  name: "forcedOfPersonalPassDialog",
  props: {
    dialogVisible: Boolean,
    passForm: Object,
  },
  data() {
    const regPass = /^(?![0-9]+$)(?![a-zA-Z]+$)[a-zA-Z0-9]{6,18}$/;
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else {
        if (regPass.test(this.passForm.password)) {
          callback();
        } else {
          callback(new Error("密码格式错误"));
        }
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.passForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      rules: {
        password: [{ validator: validatePass, trigger: "blur", required: true }],
        checkPass: [{ validator: validatePass2, trigger: "blur", required: true }],
      },
      btnAbled: false,
    };
  },

  mounted() {},
  watch: {
    dialogVisible: {
      handler(newVal) {
        this.visible = newVal;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    dialogClosed() {
      this.visible = false;
      this.$emit("sendPassVisible", false);
    },
    confirmReset(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.btnAbled = true;
          let res = await resetPassword({ id: this.passForm.id, password: md5(this.passForm.password) });
          if (res.data.code == "200") {
            this.$message({
              type: "success",
              message: "操作成功,请重新登录",
              center: true,
            });
            this.visible = false;
            this.$emit("sendPassVisible", false);
            this.btnAbled = false;
            this.$store.commit("user/logOut");
            this.$router.push("/login");
          } else {
            this.btnAbled = false;
          }
        } else {
          console.log("error submit!!");
          this.btnAbled = false;
          return false;
        }
      });
    },
  },
};
</script>
