<template>
  <div>
    <div class="top">
      <div style="display: flex; align-items: center">
        <div class="label">注塑模具管理系统</div>
      </div>

      <el-dropdown style="display: flex; align-items: center; cursor: pointer; width: auto">
        <!-- 将触发下拉菜单的元素（图片、用户名、箭头图标）全部包含在el-dropdown内 -->
        <div style="display: flex; align-items: center; cursor: pointer">
          <img src="../assets/images/icon_choho.png" alt="" style="width: 40px; height: 40px; margin-right: 10px; border-radius: 50%" />
          <span style="color: white; font-weight: bold">{{ $store.state.user.userInfo.name }}</span>
          <i class="el-icon-arrow-down" style="margin-left: 10px"></i>
        </div>
        <!-- 下拉菜单内容 -->
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="clickToResetPass">修改密码</el-dropdown-item>
          <el-dropdown-item @click.native="logout">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 修改密码对话框 -->
    <forcedOfPersonalPassDialog :dialogVisible="dialogVisible" :passForm="passForm" @sendPassVisible="onSendPassVisible"></forcedOfPersonalPassDialog>
  </div>
</template>

<script>
import forcedOfPersonalPassDialog from "@/components/forcedOfPersonalPassDialog.vue";
import store from "@/store";
export default {
  name: "Top",
  components: {
    forcedOfPersonalPassDialog,
  },
  data() {
    return {
      tableData: JSON.parse(localStorage.getItem("honey-user") || "{}"),
      collapseBtnClass: "el-icon-s-fold",
      isCollapse: false,
      sideWidth: 300,
      logoTextShow: true,
      dialogVisible: false,
      passForm: {},
    };
  },

  methods: {
    // 侧边栏收缩
    collapse() {
      //点击收缩按钮触发
      this.isCollapse = !this.isCollapse;
      if (this.isCollapse) {
        this.sideWidth = 64;
        this.collapseBtnClass = "el-icon-s-unfold";
        this.logoTextShow = false;
      } else {
        this.sideWidth = 300;
        this.collapseBtnClass = "el-icon-s-fold";
        this.logoTextShow = true;
      }
    },
    // 退出登录
    logout() {
      store.commit("user/logOut");
      this.$router.push("/login");
    },
    // 重置密码
    clickToResetPass() {
      const { id } = this.$store.state.user.userInfo;
      this.passForm = {
        id,
      };
      this.dialogVisible = true;
    },
    onSendPassVisible(data) {
      this.dialogVisible = data;
    },
  },
};
</script>
<style scoped>
.top {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.label {
  height: 60px;
  line-height: 40px;
  padding: 10px 0;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  color: #fff;
  box-sizing: border-box;
}
/* .headerBg {
  background: #effff1 !important;
}
.el-icon-s-fold,
.el-icon-s-unfold {
  font-size: 20px; /* Larger icon size */
/* } */

/* 减小drag */
.upload-demo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.custom-dragger {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50%;
  padding: 10px;
  width: 300px;
}
.el-upload__text {
  margin-top: 10px;
}
.custom-upload {
  display: flex;
  align-items: center;
}
.el-upload__tip {
  margin-top: 10px;
  color: #999;
  margin-left: 15px;
}
</style>
