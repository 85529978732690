import Vue from "vue";
import VueRouter from "vue-router";
import LayOut from "../Layout/index.vue";
import Login from "../views/Login/index.vue";
import store from "@/store";

Vue.use(VueRouter);

export const routes = [
  {
    path: "/",
    component: LayOut,
    meta: {
      til: "模具管理",
      icon: "icon-mojuguanli",
    },
    children: [
      {
        path: "",
        component: () => import("../views/Mold/index.vue"),
      },
    ],
  },
  {
    path: "/MoldStart",
    component: LayOut,
    meta: {
      til: "上机管理",
      icon: "icon-shebeiguanli",
    },
    children: [
      {
        path: "",
        component: () => import("../views/MoldStart/index.vue"),
      },
    ],
  },
  // {
  //   path: "/MoldEnd",
  //   component: LayOut,
  //   meta: {
  //     til: "下机管理",
  //     icon: "el-icon-document-copy",
  //   },
  //   children: [
  //     {
  //       path: "",
  //       component: () => import("../views/MoldEnd/index.vue"),
  //     },
  //   ],
  // },
  {
    path: "/MoldRepair",
    component: LayOut,
    meta: {
      til: "维修管理",
      icon: "icon-weixiuguanli",
    },
    children: [
      {
        path: "",
        component: () => import("../views/MoldRepair/index.vue"),
      },
    ],
  },
  {
    path: "/MoldMaintenance",
    component: LayOut,
    meta: {
      til: "保养管理",
      icon: "icon-baoyangguanli1",
    },
    children: [
      {
        path: "",
        component: () => import("../views/MoldMaintenance/index.vue"),
      },
    ],
  },
  {
    path: "/User",
    component: LayOut,
    meta: {
      til: "用户管理",
      icon: "icon-yonghuguanli",
    },
    children: [
      {
        path: "",
        component: () => import("../views/User/index.vue"),
      },
    ],
  },
  {
    path: "/Login",
    component: Login,
    meta: {
      hidden: true,
      til: "登录",
    },
  },
  {
    path: "/404",
    component: () => import("../views/404/index.vue"),
    meta: {
      hidden: true,
      til: "404",
    },
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/404",
    meta: {
      hidden: true,
    },
  },
];
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};
const router = new VueRouter({
  mode: "history",
  routes,
});
router.beforeEach((to, from, next) => {
  const token = store.state.user.token;
  if (to.path == "/login" || to.path == "/404" || token) {
    next();
  } else {
    next("/login");
  }
});

export default router;
