import request from "@/utils/request";
// 用户列表
export const userList = (data) => request.post("/user/selectUserPage", data);
// 新增用户
export const addUser = (data) => request.post("/user/adduser", data);
// 修改用户
export const editUser = (data) => request.post("/user/update", data);
// 用户详情
export const userDetail = (uid) => request.get("/user/getUserInfo", { params: { uid } });
// 修改用户状态
export const changeUserStatus = (data) => request.post("/user/updateStatus", data);
// 重置密码
export const resetPassword = (data) => request.post("/user/reSetPaassword", data);
